import React, { useState } from 'react';
import { Card, CardMedia } from '@mui/material'
import { PriceLabel } from "components/misc/Price";
import { Buy } from "components/misc/buy/Buy";
import { Bid } from "components/misc/bid/Bid";
import TextField from '@mui/material/TextField';
import recycle from "assets/recycle.png";
import auction from "assets/auction.png";
import { yocto_string_to_near } from 'helpers'
import { ListingDetails } from './ListingDetails';
import { EneftigoModal } from 'EneftigoModal';
import { Link } from "react-router-dom";
import { borderRadius } from '@mui/system';

const humanDate = (timestamp) => {
    if (timestamp) {
        const options = { year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric" }
        return new Date(timestamp / 1000000).toLocaleTimeString(undefined, options);
    } else {
        return null;
    }
};

export function MyListingCard({ listing, showDetails, handleShowDetails, handleHideDetails }) {

    const acceptableBidNear = listing.acceptable_bid_yocto ? yocto_string_to_near(listing.acceptable_bid_yocto, 1) : null;
    const [bid, setBid] = useState(acceptableBidNear);

    const bidValidator = (text) => {
        if (text === "") {
            setBid(text);
            return;
        }
        const cleaned = text.toLowerCase().replace(/[^0-9.]+/g, "");
        const number = parseFloat(cleaned);
        if (isNaN(number))      // if whitespaces only
            return;
        const rounded = Math.floor(number * 10 ** 2) / 10 ** 2;
        if (rounded === number)
            setBid(cleaned);
        else
            setBid(rounded);
    };

    return (
        <div style={{ position: "relative" }}>
            <Card
                variant="elevation"
                sx={{
                    height: 420,
                    width: 280,
                }}>
                <div
                    className='listing_card_content'
                    style={{ position: 'absolute', top: '1em', left: '0em', bottom: '0em', width: '100%' }}
                >
                    <div style={{ position: 'absolute', width: '100%', height: '40%' }}>
                        <div style={{ animationDuration:'2s', animationName:'fadein', position: 'absolute', top: '1em', right: '1em', bottom: '0.5em', left: '1em', overflow: 'hidden', borderRadius: '0.5em' }}>
                            <CardMedia
                                onClick={handleShowDetails}
                                style={{ objectFit: 'cover', height: "100%" }}
                                component="img"
                                image={listing.nft_metadata.media}
                                alt="Media"
                            />
                        </div>
                    </div>
                    <div style={{ position: 'absolute', width: '100%', height: '60%', bottom: '0em' }}>
                        <div style={{ position: 'absolute', top: '0.5em', right: '1em', bottom: '1em', left: '1em', borderRadius: '0.5em' }}>
                            <ListingInfo listing={listing} />
                        </div>
                    </div>
                </div>
                <div style={{ position: 'absolute', top: '0.2em', left: '1em', height: '0.75em', width: '42%' }}>
                    <ListingType text={"Music NFT"} />
                </div>
                <div style={{ position: 'absolute', top: '0.2em', right: '1em', height: '0.75em', width: '42%' }}>
                    <ListingType text={"Popular Music"} />
                </div>

                {/* {listing.is_secondary &&
                    <img style={{ position: "absolute", top: "5px", left: "8px" }} src={recycle} width="24" height="24" alt="N" />
                }
                {
                    listing.min_bid_yocto != null &&
                    <img style={{ position: "absolute", top: "5px", right: "8px" }} src={auction} width="24" height="24" alt="N" />
                }
                <p>{listing.nft_metadata.title}</p>
                <Link to={"/listings/" + listing.seller_id} className="site-title" style={{ padding: "0px" }}>
                    <p style={{ fontFamily: "var(--eneftigo-header-font-family)" }}>{listing.seller_id}</p>
                </Link>
                <CardMedia
                    onClick={handleShowDetails}
                    style={{ borderRadius: "4px" }}
                    component="img"
                    height="180"
                    image={listing.nft_metadata.media}
                    alt="Media"
                />
                <p style={{ fontSize: "10px" }}>
                    {
                        listing.is_secondary ?
                            "SELLING BY: TODO" :
                            "AVAILABLE: " + listing.supply_left
                    }
                </p>
                {
                    listing.price_yocto &&
                    <div style={{ marginLeft: "16px", marginRight: "16px", display: "flex", justifyContent: "space-between", alignItems: "center", gap: "18px" }}>
                        <Buy listing={listing} />
                        <PriceLabel price_yocto={listing.price_yocto} />
                    </div>
                }
                {
                    listing.min_bid_yocto &&
                    <div style={{ marginLeft: "0px", marginRight: "0px", marginTop: "8px", display: "flex", justifyContent: "center", alignItems: "center", gap: "8px" }}>
                        <Bid listing={listing} bidAmount={bid} />
                        <div style={{ display: "flex" }}>
                            <TextField
                                value={bid}
                                size="small"
                                style={{ width: "60px" }}
                                sx={{ width: "60px" }}
                                required
                                // label="bid amount (Near)"
                                onChange={(e) => bidValidator(e.target.value)}
                                autoComplete="off"
                            />
                            <img src={require("assets/near_icon_light.png")} style={{ height: "26px", margin: "-1px 0px 0px 0px" }} alt="N" />
                        </div>
                    </div>
                } */}
            </Card >
            <EneftigoModal
                sx={{ margin: "0px" }}
                open={showDetails}
                title="LISTING DETAILS"
                handleClose={handleHideDetails}
                content={<ListingDetails listing={listing} />}
            />
        </div >
    );
}

function ListingType({ text }) {

    return (
        <div className='listing_card_info' style={{ fontFamily: 'var(--eneftigo-header-font-family', position: 'relative', borderRadius: '0.3em', padding: '0.3em 1em 0.3em 1em' }}>
            <p style={{ margin: 'auto', fontSize: '0.7em', fontWeight: 'lighter' }}>{text}</p>
        </div>
    );
}

function ListingInfo({ listing }) {
    return (
        <div className='listing_card_info' style={{ borderRadius: '0.5em', position: 'absolute', width: '100%', height: '100%' }}>
            <p style={{ position: 'absolute', marginTop: '0.75em', left: '0.75em', top: '0', fontSize: '1.1em', fontWeight: 'bolder' }}>{listing.nft_metadata.title}</p>
            <p style={{ position: 'absolute', marginTop: '0.75em', right: '0.75em', top: '0', fontSize: '1.1em', fontWeight: 'bolder' }}>#{listing.supply_total}</p>
            <audio
                style={{ position: 'relative', margin: 'auto', width: '90%', top: '4em', height: '1.5em' }}
                controls
                controlsList="nodownload nofullscreen"
                src="/media/cc0-audio/t-rex-roar.mp3"
            />
            <div style={{ position: 'absolute', margin: 'auto', top: '50%', height: '15%', left: '0.75em', lineHeight: '0.33em', textAlign: 'left' }}>
                <p style={{ opacity: '75%', fontSize: '0.8em', fontWeight: 'lighter' }}>Seller</p>
                <p style={{ fontSize: '0.8em', fontWeight: 'lighter' }}>@{listing.seller_id}</p>
            </div>
            <div style={{ position: 'absolute', margin: 'auto', top: '50%', height: '15%', right: '0.75em', lineHeight: '0.33em', textAlign: 'left' }}>
                <p style={{ opacity: '75%', fontSize: '0.8em', fontWeight: 'lighter' }}>Ends</p>
                <p style={{ fontSize: '0.8em', fontWeight: 'lighter' }}>{listing.end_timestamp ? humanDate(listing.end_timestamp) : "Open-Ended"}</p>
            </div>
            <div style={{ position: 'absolute', margin: 'auto', top: '72%', height: '15%', left: '0.75em', lineHeight: '0.2em', textAlign: 'left' }}>
                <p style={{ opacity: '75%', fontSize: '0.8em', fontWeight: 'lighter' }}>{listing.price_yocto ? "Price" : "Highest Bid"}</p>
                <PriceLabel style={{ position: 'absolute', left:'0em'}} price_yocto={listing.price_yocto ?? listing.min_bid_yocto} />
            </div>
            <div style={{ position: 'absolute', margin: 'auto', top: '78%', height: '25%', right: '-0.5em' }}>
            {listing.price_yocto ? <Buy listing={listing}/> : <Bid listing={listing} />}
            </div>
        </div>
    );
}
